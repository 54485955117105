<template>
  <el-scrollbar tag="div" class="sidBarss">
    <el-menu default-active="onRoutes" :collapse="false" class="el-menu-vertical-demo" @open="handleOpen"
      @select="handleSelect" @close="handleClose" router>
      <!-- <el-menu-item index="/Enterprise">EnteroriseNewly -->

      <el-menu-item index="/EnteroriseNewly" v-if="QXList[0].checked">
        <!-- <i class="el-icon-office-building"></i> -->
        <img v-show="savePath=='/EnteroriseNewly'" src="../../assets/img/Qiyesel.png">
        <img v-show="savePath!='/EnteroriseNewly'" src="../../assets/img/Qiye.png">
        <span slot="title">企业认证</span>
      </el-menu-item>

      <el-menu-item index="/AdminAccount"  v-if="QXList[1].checked">

        <!-- v-show="savePath=='/AdminAccount'" -->
        <!-- <i class="el-icon-user"></i> -->
        <img v-show="savePath=='/AdminAccount'" src="../../assets/img/Guanliyuansel.png" alt="" srcset="">
        <img v-show="savePath!='/AdminAccount'" src="../../assets/img/Guanliyuan.png" alt="" srcset="">
        <span slot="title">管理员设置</span>
      </el-menu-item>

      <el-menu-item index="/InfoMsettings"  v-if="QXList[2].checked">
        <!-- <i class="el-icon-monitor"></i> -->
        <img v-show="savePath=='/InfoMsettings'" src="../../assets/img/Jibensel.png" alt="" srcset="">
        <img v-show="savePath!='/InfoMsettings'" src="../../assets/img/Jiben.png" alt="" srcset="">
        <span slot="title">基本信息设置</span>
      </el-menu-item>

      <el-menu-item index="/FullModule"  v-if="QXList[3].checked">
        <!-- <i class="el-icon-bell"></i> -->
        <img v-show="savePath=='/FullModule'" src="../../assets/img/Gongnengsel.png" alt="" srcset="">
        <img v-show="savePath!='/FullModule'" src="../../assets/img/Gongneng.png" alt="" srcset="">
        <!-- <img src="../../assets/img/Gongneng.png" alt="" srcset=""> -->
        <span slot="title">功能权限设置</span>
      </el-menu-item>

      <el-menu-item index="/Mubiao"  v-if="QXList[4].checked">
        <!-- <i class="el-icon-connection"></i> -->
        <img v-show="savePath=='/Mubiao'" src="../../assets/img/Mubiaosel.png" alt="" srcset="">
        <img v-show="savePath!='/Mubiao'" src="../../assets/img/Mubiao.png" alt="" srcset="">
        <!-- <img src="../../assets/img/Mubiao.png" alt="" srcset=""> -->
        <span slot="title">目标设置</span>
      </el-menu-item>

      <el-menu-item index="/MarketingCampaign"  v-if="QXList[5].checked">
        <img v-show="savePath=='/MarketingCampaign'" src="../../assets/img/Yingxiaosel.png" alt="" srcset="">
        <img v-show="savePath!='/MarketingCampaign'" src="../../assets/img/Yingxiao.png" alt="" srcset="">
        <!-- <i class="el-icon-chat-dot-square"></i> -->
        <!-- <img src="../../assets/img/Yingxiao.png" alt="" srcset=""> -->
        <span slot="title">营销设置</span>
      </el-menu-item>

      <el-menu-item index="/AnalyseDataSet"  v-if="QXList[6].checked">
        <!-- <i class="el-icon-truck"></i> -->
        <img v-show="savePath=='/AnalyseDataSet'" src="../../assets/img/Caigousel.png" alt="" srcset="">
        <img v-show="savePath!='/AnalyseDataSet'" src="../../assets/img/Caigou.png" alt="" srcset="">
        <!-- <img src="../../assets/img/Caigou.png" alt="" srcset=""> -->
        <span slot="title">采购管理</span>
      </el-menu-item>

      <el-menu-item index="/Centrepot"  v-if="QXList[7].checked">
        <!-- <i class="el-icon-takeaway-box"></i> -->
        <img v-show="savePath=='/Centrepot'" src="../../assets/img/Cangkusel.png" alt="" srcset="">
        <img v-show="savePath!='/Centrepot'" src="../../assets/img/Cangku.png" alt="" srcset="">
        <!-- <img src="../../assets/img/Cangku.png" alt="" srcset=""> -->
        <span slot="title">仓库管理</span>
      </el-menu-item>

      <el-menu-item index="/DishManagement"  v-if="QXList[8].checked">
        <!-- <i class="el-icon-dish"></i> -->
        <img v-show="savePath=='/DishManagement'" src="../../assets/img/Caipinsel.png" alt="" srcset="">
        <img v-show="savePath!='/DishManagement'" src="../../assets/img/Caipin.png" alt="" srcset="">
        <!-- <img src="../../assets/img/Caipin.png" alt="" srcset=""> -->
        <span slot="title">菜品管理</span>
      </el-menu-item>
      <el-menu-item index="/DeskManagement"  v-if="QXList[9].checked">
      <img v-show="savePath=='/DeskManagement'" src="../../assets/img/Zhuotaisel.png" alt="" srcset="">
        <img v-show="savePath!='/DeskManagement'" src="../../assets/img/Zhuotai.png" alt="" srcset="">
        <!-- <i class="el-icon-film"></i> -->
        <!-- <img src="../../assets/img/Zhuotai.png" alt="" srcset=""> -->
        <span slot="title">桌台管理</span>
      </el-menu-item>

      <el-menu-item index="/PeopleManage"  v-if="QXList[10].checked">
        <!-- <i class="el-icon-sort"></i> -->
        <img v-show="savePath=='/PeopleManage'" src="../../assets/img/Renshisel.png" alt="" srcset="">
        <img v-show="savePath!='/PeopleManage'" src="../../assets/img/Renshi.png" alt="" srcset="">
        <!-- <img src="../../assets/img/Renshi.png" alt="" srcset=""> -->
        <span slot="title">人事管理</span>
      </el-menu-item>

      <!-- <el-menu-item index="/workdayShop"  v-if="QXList[11].checked">
      <img v-show="savePath=='/workdayShop'" src="../../assets/img/Caiwusel.png" alt="" srcset="">
        <img v-show="savePath!='/workdayShop'" src="../../assets/img/Caiwu.png" alt="" srcset=""> -->
        <!-- <i class="el-icon-edit"></i> -->
        <!-- <img src="../../assets/img/Caiwu.png" alt="" srcset=""> -->
        <!-- <span slot="title">财务管理</span>
      </el-menu-item> -->

      <el-menu-item index="/ApprovalList"  v-if="QXList[12].checked">
      <!-- <img v-show="savePath=='/ApprovalList'" src="../../assets/img/Sensel.png" alt="" srcset=""> -->
        <!-- <img v-show="savePath!='/ApprovalList'" src="../../assets/img/Sen.png" alt="" srcset=""> -->
        <!-- <i class="el-icon-edit"></i> -->
        <img class="icon-img" v-show="savePath=='/ApprovalList'" src="../../assets/img/shenpishezhi_z.png" alt="" srcset="">

        <img class="icon-img" v-show="savePath!=='/ApprovalList'" src="../../assets/img/Sen.png" alt="" srcset="">

        <span slot="title">审批设置</span>
      </el-menu-item>
       <!-- <el-menu-item index="/Public" v-if="user_id == 'YH16186463208788r46' || user_id == 'YH1617629895864m811'||QXList[13].checked">
        <img class="icon-img" src="../../assets/img/biaodanyemian_bai.png" alt="" srcset="">
        <span slot="title">公共表单</span>
      </el-menu-item> -->
      <el-menu-item index="/supplier"  v-if="QXList[14].checked">
        <!-- <i class="el-icon-sort"></i> -->
        <img v-show="savePath=='/supplier'" src="../../assets/img/gongyingshang_z.png" alt="" srcset="">
        <img v-show="savePath!='/supplier'" src="../../assets/img/gongyingshang_bai.png" alt="" srcset="">
        <!-- <img src="../../assets/img/Renshi.png" alt="" srcset=""> -->
        <!-- <span slot="title">供应商</span> -->
        <span slot="title">数据中心</span>
      </el-menu-item>

      <!-- <el-menu-item index="/duizhang" v-if="QXList[15].checked" >
        <img v-show="savePath=='/duizhang'" src="../../assets/img/zhangwumingxi_z.png" alt="" srcset="">
        <img v-show="savePath!='/duizhang'" src="../../assets/img/zhangwumingxi_bai.png" alt="" srcset="">
        <span slot="title">账务明细</span>
      </el-menu-item> -->

      <el-menu-item index="/yingjianshezhi" v-if="QXList[16].checked" >
        <!-- <i class="el-icon-sort"></i> -->
        <img v-show="savePath=='/yingjianshezhi'" src="../../assets/img/yingjiansezhi_z.png" alt="" srcset="">
        <img v-show="savePath!='/yingjianshezhi'" src="../../assets/img/yingjianshezhe_bai.png" alt="" srcset="">
        <!-- <img src="../../assets/img/Renshi.png" alt="" srcset=""> -->
        <span slot="title">硬件设置</span>
      </el-menu-item>
      <!-- // earl 2021-09-06 新添加会员管理导航 -->
      <el-menu-item index="/manageSetting" v-if="QXList[17].checked" >
        <!-- <i class="el-icon-sort"></i> -->
        <img v-show="savePath=='/manageSetting'" src="../../assets/img/yingjiansezhi_z.png" alt="" srcset="">
        <img v-show="savePath!='/manageSetting'" src="../../assets/img/yingjianshezhe_bai.png" alt="" srcset="">
        <!-- <img src="../../assets/img/Renshi.png" alt="" srcset=""> -->
        <span slot="title">会员管理</span>
      </el-menu-item>
      <!-- // earl 2021-09-07 新添加assets导航 -->
      <el-menu-item index="/paymentSetting" v-if="QXList[18].checked" >
        <!-- <i class="el-icon-sort"></i> -->
        <img v-show="savePath=='/paymentSetting'" src="../../assets/img/yingjiansezhi_z.png" alt="" srcset="">
        <img v-show="savePath!='/paymentSetting'" src="../../assets/img/yingjianshezhe_bai.png" alt="" srcset="">
        <!-- <img src="../../assets/img/Renshi.png" alt="" srcset=""> -->
        <span slot="title">支付设置</span>
      </el-menu-item>

      <!-- <el-menu-item index="/Approval">
        <i class="el-icon-setting"></i>
        <span slot="title">审批设置</span>
      </el-menu-item>
      <el-menu-item index="/Cloud">
        <i class="el-icon-setting"></i>
        <span slot="title">云盘中心</span>
      </el-menu-item>
      <el-menu-item index="/Inquire">
        <i class="el-icon-setting"></i>
        <span slot="title">查询管理</span>
      </el-menu-item> -->

    </el-menu>
  </el-scrollbar>
</template>
<script>
// query_user_auth
    import {query_user_auth} from '@api/foodqiye'

  export default {
    name: 'SidBar',
    data() {
      return {
        Adminrouter: '/AdminAccount',
        savePath: '',
        user_id: "",

        QXList:[
          {
          id:'0',
          checked:false,
          EnteroriseNewly_check:'', // 企业认证
          },
          {
          id:'0',
          checked:false,
          AdminAccount_check:'', // 管理员设置
          },
          {
          id:'1',
          checked:false,
          InfoMsettings_check:'', // 基本信息设置
          },
          {
          id:'2',
          checked:false,
          FullModule_check:'', // 功能权限设置
          },
          {
          id:'3',
          checked:false,
          Mubiao_check:'', // 目标设置
          },
          {
          id:'4',
          checked:false,
          MarketingCampaign_check:'', // 营销设置
          },
          {
          id:'5',
          checked:false,
          AnalyseDataSet_check:'' // 采购管理
          },
          {
          id:'6',
          checked:false,
          Centrepot_check:'', // 仓库管理
          },
          {
          id:'7',
          checked:false,
          DishManagement_check:'', // 菜品管理
          },
          {
          id:'8',
          checked:false,
          DeskManagement_check:'', // 桌台管理
          },
          {
          id:'9',
          checked:false,
          PeopleManage_check:'', // 人事管理
          },
          {
          id:'10',
          checked:false,
          workdayShop_check:'', // 财务管理
          },
          {
          id:'11',
          checked:false,
          ApprovalList_check:'', // 审批设置
          },
          {
          id:'12',
          checked:false,
          Public_check:'', // 公共表单
          },
          {
          id:'13',
          checked:false,
          supplier_check:'', // 供应商
          },
          {
          id:'14',
          checked:false,
          supplier_check:'', // 账务明细
          },
          {
          id:'15',
          checked:false,
          supplier_check:'', // 硬件设置
          },
          {
          id:'16',
          checked:false,
          supplier_check:'', // 会员管理 2021-09-06 earl添加
          },{
          id:'17',
          checked:false,
          supplier_check:'', // 支付设置 2021-09-07 earl添加
          },
        ]
      }
    },
    
    // computed: {
    //     ent_id() {
    //         console.log('计算属性的中的ent_id',this.$store.state.ent_id)
    //         return this.$store.state.ent_id
    //     },
    // },
    created(){
      this.user_id = sessionStorage.getItem('user_id')
  
      this.ent_id = this.$store.state.ent_id;
      console.log(this.ent_id,'this.$store.state.ent_id')
          
      // this.ent_id = this.$store.state.userInfo.;
    },
    mounted(){
      this.$nextTick( () =>{
        this.query_user_auth()
        this.huoqudanqian_router()
      })
      
    },

    methods: {
      handleSelect(val, keyPath) {
        this.savePath = val
        console.log(val)
      },
      huoqudanqian_router(){
        // console.log('==================',this.$route.path);
         this.savePath = this.$route.path
      },

      handleOpen() {},
      handleClose() {},
      onRoutes() {},
      handleAdmin() {
        const Aamin_ent_id = JSON.parse(sessionStorage.getItem('pc_ent_id'))
      },
      query_user_auth(){ //权限
        let data = {
          ent_id: this.$store.state.ent_id,
          user_id:this.user_id
        }
        console.log('===',data);
        query_user_auth({
            data
        }).then(res => {
            console.log(this.$store.state.jurisdiction ,'qqqqqqqqq');
            console.log(res ,'qqqqqqqqq===1111111111111');

            // let jurisdiction = this.$store.state.jurisdiction
            if (res.code =='200') {
              let jurisdiction = res.body.data;
              console.log(jurisdiction,'jurisdiction')
              let QXList = this.QXList
              if (jurisdiction.flag_boss=='0') {
                // 不是老板
                if(jurisdiction.flag_main_mng=='0'){
                  // 不是主管理员
                  if (jurisdiction.flag_sub_mng=='0') {
                  // 不是子管理员
                  // web端分派的分配的权限
                  console.log('============');
                      let web_sub_mng_list=jurisdiction.web_sub_mng_list
                      console.log('==========-----=========',web_sub_mng_list);
                    if (web_sub_mng_list.length == '0') {
                        let QXList_a = this.QXList
                        for (let indb in QXList_a) {
                          console.log(QXList_a[indb]);
                          if (QXList_a[indb].id == '0') {
                            QXList_a[indb].checked = true
                          }
                        }
                        this.QXList = QXList_a
                    }else{
                    }
                  }else{
                  // 是子管理员
                  console.log('子管理员');
                  let web_sub_mng_list=jurisdiction.web_sub_mng_list // 分配的权限
                  for (let inda in QXList) {
                    web_sub_mng_list.forEach((x)=>{
                      if( QXList[inda].id == x.module_id){
                        QXList[inda].checked = true
                      }
                    })
                  }
                  this.QXList = QXList
                  console.log('gfsdkfsdfgsdfsdfg',QXList);
                  }
                }else{
                  // 是主管理员
                  console.log('主管理员');
                  for (let inda in QXList) {
                    QXList[inda].checked = true
                  }
                  this.QXList = QXList
                }
              }else{
                // 是老板
                console.log('boss老板');
                for (let inda in QXList) {
                  QXList[inda].checked = true
                }
                this.QXList = QXList
              }
            }

            if (res.code == '1000') {
              console.log(res,'=====1000===1000===1000');
              let QXList = this.QXList
                for (let inda in QXList) {
                  if (QXList[inda].id == '0') {
                    QXList[inda].checked = true
                  }
                }
                this.QXList = QXList
            }

        })

      }
    },
  }

</script>
<style lang="scss">
  .sidBarss {
    height: 100%;

    img {
      width: 20px;
      height: 20px;
    }

    span {
      margin-left: 15px;
    }
  }

</style>
