<template>
  <div class="TreeBG">
    <el-dialog title="请选择菜品" :visible.sync="IsShow" width="30%" center  :show-close='false' :modal='false' :close-on-click-modal='false'>
      <el-tree :props="props" check-strictly :load="loadNode" lazy  :data="data" node-key="id"
  ref="tree"   show-checkbox>
      </el-tree>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sure">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    query_category_tree
  } from '../../api/public'

  import {
    query_prod_info_list,
       query_cat_prod_list_of_tree
  } from '../../api/store'
  export default {
    props: ['IsShow'],
    data() {
      return {
        centerDialogVisible: false,
        data: [],
        props: {
          label: 'cat_name',
          value: 'cat_id',
          children: 'list'
        }
      }
    },
    created() {

      query_category_tree({
        data: {
          ent_id: this.$store.state.ent_id,
        }
      }).then(res => {
        console.log(res.body)
        this.data=res.body.data
        // 
      })
    },
    methods: {
      sure() {
        
        this.$emit('LCB', this.$refs.tree.getCheckedNodes());
      },
      GetTreeList() {
        // query_category_tree
      },
      loadNode(node, resolve) {
        //  
        if (node.level === 0) {
        }
        if(node.level>0){
              query_cat_prod_list_of_tree({
            data: {
              ent_id: this.$store.state.ent_id,
              cat_id:node.data.cat_id
            }
          }).then(res => {

         
          let arr=[]
          res.body.data.forEach(item => {
              item.cat_id=item.prod_id
              item.cat_name=item.prod_name
            arr.push(item)
          });
        
            return resolve(arr);
          })
        }
        
      }

    }
  }

</script>

<style lang="scss" scoped>
  .TreeBG {}

</style>
