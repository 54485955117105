<template>
  <div class="home">
   <el-container>
  <el-header height="100px">
    <nav-Top></nav-Top>
  </el-header>
  <el-container>
    <el-aside width="230px">
         <sid-bar></sid-bar>
    </el-aside>
    <el-main v-loading="loading">
      <router-view/>
    </el-main>
  </el-container>
</el-container>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import navTop from '@c/navTop'
import sidBar from '@c/sidBar'
export default {
  name: 'Home',
  components: {
    navTop,
    sidBar
  },
  computed:{
    ...mapState(['loading'])
  }
}
</script>
<style lang="scss">
.fade-enter {
  opacity:0;
}
.fade-leave{
  opacity: 1;
}
.fade-leave-active,.fade-enter-active {
 transition:opacity 0.3s;
}
.home{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.el-aside{
  height: 100vh;
  overflow: hidden;
  padding-bottom: 100px;
}
//隐藏滚动条
.el-main::-webkit-scrollbar {
    display: none;
}
</style>
