<template>
  <div class="header">
    <div class="header-left">
      <div class="header-left-logo">
        <span style="font-size: 30px; font-weight: bold; color: #c00;">华品富商餐饮盈利系统</span>
        <!-- <img class="header-left-logo-img" :src="headerCon.logo" alt="" /> -->
      </div>
      <!-- <div v-if="quanxian === '18600589271'" style="font-size:14px;margin-left:30px;"> -->
        <!-- <i class="el-icon-share" style="color:red;font-size:22px;" @click="$router.push('/public')"></i> -->
      <!-- </div> -->
    </div>
    <div class="header-right">
      <!-- <p style="font-size:16px;" @click="IsShow=true">测试树</p> -->
      <!-- <div class="header-right-tx">
        <img class="header-right-tx-img" :src="$store.state.userInfo.avatar" alt="" />
      </div> -->
      <!-- <p class="header-right-p">{{ gsname == ''? '请选择企业': gsname }}</p> -->
      <div class="header-right-qh">
        <el-select v-model="$store.state.ent_id" placeholder="请选择企业" class="SelectEnt"  @change="SelectEnt">
          <el-option v-for="item in dataList" v-show="item.use_status=='1' " :key="item.value" :label="item.ent_name"
            :value="item.ent_id">
          </el-option>
        </el-select>
      </div>
      <i class="el-icon-switch-button quite" @click="tuichu()"></i>
      <!-- <div @click="tuichu()" class="header-right-tc"><img :src="headerCon.tcimg" alt="" /></div>. -->
    </div>
    <BaseTree v-if="IsShow" :IsShow='IsShow' @LCB='GetData'></BaseTree>
  </div>
</template>
<script>
  import {
    logout,
    get_my_info,
    query_user_ent_list,
    get_rel_list,
    toggle_user_ent
  } from '@api/pulic'

  import BaseTree from '../baseComponents/tree'
  import {
    mapState,
    mapActions
  } from 'vuex'

  import {
    query_user_aut_ent_list
  } from '../../api/store'
  import {query_user_auth} from '@api/foodqiye'

  export default {
    name: 'NavTop',
    components: {
      BaseTree
    },
    data() {
      return {
        ent_id: '',
        IsShow: false,
        quanxian: null,
        headerCon: {
          tximg: require('@img/tx.png'),
          logo: require('@img/logo.png'),
          qhimg: require('@img/qiehuan.png'),
          tcimg: require('@img/tuichu.png'),
        },
        gsname: '',
        list: false,
        dataList: [],
        QYS: {}
      }
    },
    created() {
      // this.quanxian = JSON.parse(sessionStorage.getItem('userInfo')).mobile
      let self = this

      get_rel_list({
        data: {
          user_id: this.$store.state.user_id
        }
      }).then(res => {
        console.log('res==========================',res);
        if (res.body.data.ent_flag == '0') {
          self.ent_id = ''
          this.$message({
            message: '请先选择企业如没有企业请先认证企业',
            type: 'error'
          })
        } else {
          self.$store.state.ent_id = res.body.data.staff_info.ent_id
          self.ent_id = res.body.data.staff_info.ent_id




          console.log(self.ent_id)
          
        }

      })
      this.GetMyInfo()
      this.GetEntList()
      // if (this.ent_id!='') {
      //   console.log('------------------');
      //   this.query_user_auth(this.ent_id,this.$store.state.user_id)
      // }
    },
    methods: {
      ...mapActions(['get_shop_list','get_cat_list']), 

      SelectEnt(ent_id) {
        console.log('------------------',ent_id);
        this.$store.state.loading = true
        
        let item = {}
        for (const i in this.dataList) {
          // if (this.dataList[i].ent_id == this.ent_id) {
          //   item = this.dataList[i]
          // }
          if (this.dataList[i].ent_id == ent_id) {
            item = this.dataList[i]
          }
        }
        console.log('切换企业的数据',item);
        if (item.use_status != 0) {
                console.log('666666666',)
          if (item.node_flag != 0) {
    
            if (item.node_not_enough != 0) {
              toggle_user_ent({
                data: {
                  user_id: this.$store.state.user_id,
                  ent_id: ent_id
                }
              }).then(res => {
          
                sessionStorage.setItem('ent_id', ent_id)
                
                this.$store.state.ent_id = ent_id
                console.log(this.$store.state.ent_id)
                
                

                this.$store.state.qyInfo = item
                this.get_cat_list() 
                sessionStorage.removeItem('isLogin')
                sessionStorage.removeItem('ent_info')
                this.query_user_auth(ent_id,this.$store.state.user_id)


                setTimeout( ()=>{
                   this.$router.go(0)
                  //  this.$store.state.ent_id = ent_id
                   this.$store.state.loading = false
                },1000)


                if (this.$route.path == '/Admin' || this.$route.path == '/AdminAccount' || this.$route.path == '/AdminForget') {
                  this.$router.push('/AdminAccount')
                }
              })
            } else {
              this.$message({
                message: '当前登录节点少于付费节点数，请联系主管理员进行设置',
                type: 'error'
              })
              return
            }
          } else {
            this.$message({
              message: '您没有该企业的登录权限，请联系主管理员添加',
              type: 'error'
            })
            return
          }
        } else {
          this.$message({
            message: '该企业暂不能使用，请联系官方客服',
            type: 'error'
          })
          return
        }

      },
      GetEntList() {
        query_user_ent_list({
          data: {
            user_id: this.$store.state.user_id
          }
        }).then(res => {
          // 
          let list = res.body.data
          console.log('获取相关企业信息',list)
          this.dataList = list
        })
      },
      GetMyInfo() {
        let self = this
        // 获取我的基础信息
        get_my_info({
          data: {
            user_id: self.$store.state.user_id
          }
        }).
        then(ras => {

          if (ras.code == 10069) {
            self.$store.state.userInfo = ras.body.data;
            self.$store.state.ent_id = ras.body.data.ent_info.ent_id
             this.get_shop_list()
             this.get_cat_list() 
          } else {
            
            self.$store.state.ent_id = ''
            self.$store.state.userInfo = {}
          }
        })

      },

      tuichu() {

        this.$confirm('是否退出?', '退出', {
          confirmButtonText: '退出',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
              logout({
          data: {
            mobile: this.$store.state.userInfo.mobile
          }
        }).then(res => {
          this.$store.state.userInfo = {}
          this.$message({
            message: '退出成功',
            type: 'success'
          })
          sessionStorage.removeItem('ent_info')
          sessionStorage.removeItem('isLogin')
          sessionStorage.removeItem('jurisdiction') // 权限
          sessionStorage.removeItem('ent_id') // 权限



          this.$router.push('/')
        })
        })

      },

      query_user_auth(ent_id,user_id){
        let data = {
          ent_id:ent_id,
          user_id:user_id
        }
        console.log('===',data);


        query_user_auth({
            data
        }).then(res => {
          if (res.code==200) {
            console.log('qqqqqqqqq', data, res);
            let jurisdiction = res.body.data
            this.$store.state.jurisdiction = jurisdiction
            sessionStorage.setItem('jurisdiction',JSON.stringify(jurisdiction))
            let router = jurisdiction.web_sub_mng_list[0].router
            this.$router.push(`/${router}`)
          }
        })
      }
    },
    watch:{
      '$store.state.ent_id'(){
        // 
        console.log('监听企业id',this.ent_id);
        this.GetEntList()
      }
    }

  }
</script>
<style lang="scss">
  @import '@css/elementuidef.scss';

  .header {
    width: 100%;
    height: 100%;
    display: flex;
    -moz-box-shadow: 0px 6px 4px #e8e8e8;
    -webkit-box-shadow: 0px 1px 12px #e8e8e8;
    box-shadow: 0px 1px 12px #e8e8e8;
    justify-content: space-between;

    &-left {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      &-logo {
        width: 150px;
        margin-left: 30px;
        overflow: hidden;

        &-img {
          width: 100%;
          height: auto;
        }
      }
    }

    &-right {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      div {
        margin-left: 10px;
        margin-right: 10px;
      }

      &-p {
        font-size: 17px;
        color: #333;
      }

      &-tx {
        width: 60px;
        height: 60px;
        border-radius: 60px;
        overflow: hidden;

        &-img {
          width: 100%;
          height: auto;
        }
      }

      &-tc {
        width: 28px;
        height: 28px;
      }
    }
  }



  .list {
    position: relative;

    .list_span {
      position: absolute;
      left: -10px;
      top: -10px;
      display: block;
      font-size: 18px;
      width: 30px;
      height: 30px;
      background: #fc4353;
      color: #fff;
      text-align: center;
      line-height: 30px;
    }
  }

  .list_box {
    width: 180px;
    position: absolute;
    left: -224px;
    // transform: translate(-50%,0);
    top: 40px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    height: 200px;
    overflow-x: auto;
    padding-top: 20px;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #f3f3f3;
    }

    /*定义滚动条轨道 内阴影+圆角*/
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0px #f3f3f3;
      border-radius: 10px;
      background-color: #f3f3f3;
    }

    /*定义滑块 内阴影+圆角*/
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 0px #b9b8b8;
      background-color: #b9b8b8;
    }

    p {
      font-size: 16px;
      text-align: center;
      color: #fff;
      margin-bottom: 20px;
      display: block;
      display: -webkit-box;
      width: 120px;
      margin: 0 auto 20px auto;
      /* autoprefixer: off */
      -webkit-box-orient: vertical;
      /* autoprefixer: on */
      -webkit-line-clamp: 1;
      overflow: hidden;
      transition: 0.5s;

      &:hover {
        color: #fc4353;
        cursor: pointer;
      }
    }
  }

  .SelectEnt {
    width: 150px;
  }

  .quite {
    font-size: 32px;
    margin-right: 20px;
    color: #ccc;

    &:hover {
      cursor: pointer;
      color: red;
    }
  }


  .header-left-logo{ width: auto; }
</style>